@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Light.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Light.woff')
      format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Light.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Light.woff')
      format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Light.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Light.woff')
      format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Regular.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Medium.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Medium.woff')
      format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Medium.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Medium.woff')
      format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Medium.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Medium.woff')
      format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Regular.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Regular.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Light.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Light.woff')
      format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Medium.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Medium.woff')
      format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bagoss Condensed';
  src: url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Regular.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/BagossCondensed-Regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.proprioo.fr/assets/fonts/Inter-SemiBold.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/Inter-SemiBold.woff')
      format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.proprioo.fr/assets/fonts/Inter-Thin.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/Inter-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.proprioo.fr/assets/fonts/Inter-Bold.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/Inter-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.proprioo.fr/assets/fonts/Inter-ExtraBold.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/Inter-ExtraBold.woff')
      format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.proprioo.fr/assets/fonts/Inter-Medium.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.proprioo.fr/assets/fonts/Inter-Regular.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/Inter-Regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.proprioo.fr/assets/fonts/Inter-Black.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/Inter-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.proprioo.fr/assets/fonts/Inter-Light.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/Inter-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('https://cdn.proprioo.fr/assets/fonts/Inter-ExtraLight.woff2')
      format('woff2'),
    url('https://cdn.proprioo.fr/assets/fonts/Inter-ExtraLight.woff')
      format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
